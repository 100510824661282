import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { mintSingleton } from 'src/client/mint';
import { FREESTAR_PRIMIS_VIDEO_RELOAD_ON_ROUTE_ENABLED } from 'src/common/constants/experiments/ads';
import useExperiment from 'src/common/hooks/useExperiment';
import useOnMount from 'src/common/hooks/useOnMount';
import usePrevious from 'src/common/hooks/usePrevious';
import { selectIsNowPlayingDialogOpen } from 'src/common/selectors/dialog';

const placementName = 'FreeStarVideoAdContainer';

export default function FreeStarVideoAdContainer({
  isInNowPlayingDialog = false,
}) {
  const isNowPlayingDialogOpen = useSelector(selectIsNowPlayingDialogOpen);
  const history = useHistory();
  const previousLocationPathname = usePrevious(history.location.pathname);
  const isFreestarPrimisVideoReloadOnRouteEnabled = useExperiment(
    FREESTAR_PRIMIS_VIDEO_RELOAD_ON_ROUTE_ENABLED,
  );

  const reloadVideoAd = () => {
    mintSingleton.instance?.deleteInContentVideo?.(placementName);
    mintSingleton.instance?.requestInContentVideo?.(placementName);
  };

  useOnMount(() => {
    mintSingleton.instance?.requestInContentVideo?.(placementName);
    return () => {
      mintSingleton.instance?.deleteInContentVideo?.(placementName);
    };
  });

  useEffect(() => {
    if (!isFreestarPrimisVideoReloadOnRouteEnabled) {
      return;
    }
    if (
      !!history.location.pathname &&
      history.location.pathname !== previousLocationPathname
    ) {
      reloadVideoAd();
    }
  }, [
    history.location.pathname,
    previousLocationPathname,
    isFreestarPrimisVideoReloadOnRouteEnabled,
  ]);

  useEffect(() => {
    if (!isInNowPlayingDialog) {
      return;
    }

    if (isNowPlayingDialogOpen) {
      reloadVideoAd();
    }
  }, [isInNowPlayingDialog, isNowPlayingDialogOpen]);

  return (
    <div
      id="FreeStarVideoAdContainer"
      style={{ marginTop: '10px', marginBottom: '10px' }}
    >
      <div id="freestar-video-parent">
        <div id="freestar-video-child"></div>
      </div>
    </div>
  );
}
